import ApiService from '@/api/http/ApiService';
import axios, { AxiosPromise } from 'axios';
import Filter from '@/interfaces/api/Filter';

export default class DataApiService extends ApiService<any> {
  constructor() {
    super('general/data/');
  }

  public getVenueCsv(filter: Filter, lang?: string): AxiosPromise<string> {
    return axios.get(`${this.getBaseUrl()}venue/csv`, { params: { lang, ...filter } });
  }
}
