
import { Component, Emit, Vue, Watch } from 'vue-property-decorator';
import CustomerGroup from '@/mixins/CustomerGroup';
import { mixins } from 'vue-class-component';
import { debounce } from '@/util/helper';

interface Selection {
  search?: string;
  published?: boolean;
  verified?: boolean;
  sync?: boolean;
  deletedAt?: boolean;
  customerGroup?: CustomerGroup;
}

@Component
export default class VenueFilter extends mixins(CustomerGroup) {
  public search: string = '';
  public published: boolean | null = null;
  public verified: boolean | null = null;
  public sync: boolean | null = null;
  public customerGroup: string | null = null;
  public deletedAt: boolean = false;

  public created() {
    this.search = (this.$route.query.search as string) || '';
  }

  get selection() {
    const selection: Selection = {};
    if (this.search !== '') {
      selection.search = this.search;
    }
    if (this.published !== null) {
      selection.published = this.published;
    }
    if (this.verified !== null) {
      selection.verified = this.verified;
    }
    if (this.sync !== null) {
      selection.sync = this.sync;
    }
    if (this.customerGroup !== null) {
      selection.customerGroup = this.customerGroup as unknown as CustomerGroup;
    }
    if (this.deletedAt) {
      selection.deletedAt = true;
    }

    return selection;
  }

  get publishedStatus() {
    return [
      { text: this.$t('common.noSelection'), value: null },
      { text: this.$t('venue.filter.published'), value: true },
      { text: this.$t('venue.filter.unpublished'), value: false },
    ];
  }

  get syncStatus() {
    return [
      { text: this.$t('common.noSelection'), value: null },
      { text: this.$t('venue.filter.sync'), value: true },
      { text: this.$t('venue.filter.idle'), value: false },
    ];
  }

  get verifiedStatus() {
    return [
      { text: this.$t('common.noSelection'), value: null },
      { text: this.$t('venue.filter.verified'), value: true },
      { text: this.$t('venue.filter.unverified'), value: false },
    ];
  }

  @Watch('selection')
  public onChange() {
    const self = this;
    debounce(() => {
      this.$router.replace({ query: { ...this.$route.query, search: this.selection.search } }).catch(() => {});
      self.$emit('change', self.selection);
    }, 500);
  }
}
